import { Space } from 'antd';
import { HomeControls } from 'components/molecules/HomeControls';
import { BusinessList } from 'components/organisms/BusinessList';

export const Home = (): JSX.Element => {
  return (
    <Space size={16} direction="vertical">
      <HomeControls />
      <BusinessList />
    </Space>
  );
};
