import { Avatar, Button, Divider, List, Space, Typography } from 'antd';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import { DivisionList } from 'components/molecules/DivisionList';
import { Business } from 'models/Business';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { LegacyRef, MouseEvent, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { useAppSelector } from 'redux/store';

const { Text } = Typography;

interface Props {
  business: Business;
}

const styles: InlineStylesModel = {
  wrapper: {
    backgroundColor: 'white',
    paddingLeft: 10,
    borderRadius: 5
  },
  container: {
    alignItems: 'center'
  }
};

export const BusinessListItem = ({ business }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [showDivision, _setShowDivisions] = useState(false);
  const { customerSearch } = useAppSelector((state) => state.businessList);

  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12 },
    height: showDivision ? height : 0
  });

  /* ******************** Functions ******************** */
  const handleToggleShowDivision = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    _setShowDivisions((prevState) => !prevState);
  };

  useEffect(() => {
    if (customerSearch) {
      _setShowDivisions(true);
    }
    if (!business.divisions.length) _setShowDivisions(false);
  }, [business.divisions.length, customerSearch]);

  useEffect(() => {
    if (!customerSearch) {
      _setShowDivisions(false);
    }
  }, [customerSearch]);

  /* ******************** Render ******************** */
  return (
    <>
      <List.Item
        onClick={business.divisions.length ? handleToggleShowDivision : undefined}
        actions={[
          <Button disabled={!business.divisions.length} style={{ width: 115 }} type="text" onClick={handleToggleShowDivision} key="show-divisions" icon={<CaretSpinIcon isOpen={showDivision} />}>
            {showDivision ? 'Collapse' : 'Expand'}
          </Button>
        ]}
        style={business.divisions.length ? { ...styles.wrapper, ...{ cursor: 'pointer' } } : styles.wrapper}>
        <List.Item.Meta
          avatar={<Avatar src={business.logoUrl} />}
          title={
            <Space>
              <Text>{business.name}</Text>
              <Divider style={{ borderLeft: '1px solid black' }} type="vertical" />
              <Text style={{ color: 'rgb(24, 167, 153)' }} type="secondary">
                {business.erpId}
              </Text>
              <Divider style={{ borderLeft: '1px solid black' }} type="vertical" />
              <Text style={{ color: 'rgb(24, 167, 153)' }} type="secondary">
                {business.divisions.length} Divisions
              </Text>
            </Space>
          }
          style={styles.container}
        />
      </List.Item>
      <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden' }, ...expandAnimation }}>
        {showDivision && (
          <div ref={ref as LegacyRef<HTMLDivElement>}>
            <DivisionList divisions={business.divisions} />
          </div>
        )}
      </animated.div>
    </>
  );
};
