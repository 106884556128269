import { Space, Switch, SwitchProps, Typography } from 'antd';
import { useField } from 'formik';
import { FC } from 'react';

interface Props extends SwitchProps {
  fieldName: string;
  label?: string;
  labelPosition?: 'top' | 'right';
}

export const SwitchInput: FC<Props> = ({ fieldName, label }) => {
  const [{ value }, , { setValue }] = useField<boolean>(fieldName);

  return (
    <Space align="start" direction="horizontal">
      <Typography.Text>{label} </Typography.Text>
      <Switch checked={value} onChange={(checked): void => setValue(checked)} />
    </Space>
  );
};
