import { Col, Collapse, Row } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
const { Panel } = Collapse;

export const ReleaseNotesPage = (): JSX.Element => {
  const { version } = useParams();

  return (
    <Fragment>
      <Row justify="space-between" style={{ padding: 10, background: 'rgb(255, 234, 193)', borderRadius: 5 }}>
        <Col>
          <AntPageTitle text={'Release Notes'} />
        </Col>
      </Row>
      <Collapse defaultActiveKey={['4.1']}>
        <Panel header="Version 4.1" key="4.1">
          <div>General</div>
          <ol>
            <li>Inventory reservation settings have been added to Deployment Request Types.</li>
            <li>Available Owners for Default Dimensions and Default Fulfillment Rules are now filtered to the owners assign to the division in the Customer Management application.</li>
            <li>Minor verbiage changes have been made to the site.</li>
            <li>Release notes have been added to the website.</li>
          </ol>
        </Panel>
      </Collapse>
    </Fragment>
  );
};
