import { Form, Select, SelectProps } from 'antd';
import { useField } from 'formik';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label?: string;
  selectOptions: string[];
}

export const SelectInput = ({ fieldName, label, selectOptions, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);

  const options = selectOptions ? selectOptions.map((item) => ({ label: item, value: item })) : [];

  const handleChange = (value: string): void => {
    setValue(value);
  };

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Select
        value={value ? value : undefined}
        onChange={handleChange}
        options={options}
        {...rest}
        onFocus={(): void => setTouched(false)}
        onBlur={(): void => setTouched(true)}
        style={{ borderRadius: 5 }}
      />
    </Form.Item>
  );
};
