import { Col, Row, Switch, SwitchProps, Typography } from 'antd';
import { useField } from 'formik';
import { FC } from 'react';
interface Props extends SwitchProps {
  fieldName: string;
  label?: string;
  labelPosition?: 'top' | 'right';
  cardSize?: 'small';
  color?: string;
}

export const SwitchInputCard: FC<Props> = ({ fieldName, label, cardSize, color, ...rest }) => {
  const [{ value }, , { setValue }] = useField<boolean>(fieldName);

  if (label === 'Shipments Enabled' || label === 'Release Enabled') {
    return (
      <Col style={{ marginLeft: 8 }}>
        <Switch {...rest} checked={value} onChange={(checked): void => setValue(checked)} />
      </Col>
    );
  }

  return (
    <Row style={{ marginBottom: 10 }}>
      <Col style={{ marginRight: 10 }}>
        <Switch {...rest} checked={value} onChange={(checked): void => setValue(checked)} />
      </Col>
      <Col>
        <Typography.Text ellipsis={{ tooltip: `${label}` }} style={{ fontSize: 15, color }}>
          {label}{' '}
        </Typography.Text>
      </Col>
    </Row>
  );
};
