import { Row } from 'antd';
import * as React from 'react';
import './addRule.css';

export const AddRuleMessage: React.FC = () => {
  return (
    <Row justify="center">
      <div id="container">
        <div id="error-box">
          <div className="dot"></div>
          <div className="dot two"></div>
          <div className="face2">
            <div className="eye"></div>
            <div className="eye right"></div>
            <div className="mouth sad"></div>
          </div>
          <div className="shadow move"></div>
          <div className="message">
            <h1 className="alert">ADD RULES TO CONTINUE</h1>
          </div>
        </div>
      </div>
    </Row>
  );
};
