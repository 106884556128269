import { List } from 'antd';
import { DivisionListItem } from 'components/atoms/DivisionListItem';
import { Business } from 'models/Business';

interface Props {
  divisions: Business['divisions'];
}

export const DivisionList = ({ divisions }: Props): JSX.Element => {
  return (
    <List
      grid={{ gutter: 4, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
      dataSource={divisions.sort((a, b) => {
        const numA = parseInt(a.erpId.match(/\d+/)?.[0] || '0');
        const numB = parseInt(b.erpId.match(/\d+/)?.[0] || '0');

        return numA - numB;
      })}
      renderItem={(division): JSX.Element => <DivisionListItem division={division} />}
      rowKey={(item): string => item.id}
      style={{ padding: 8 }}
    />
  );
};
